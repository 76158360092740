import AboutBFEC from "./img/about-us/about-bfec-01.jpg";
import Visit from "./img/visit/VisitUs.jpg";
import FrontpageHero1 from "./img/frontpage/hero-01.jpg";
import FrontpageHero2 from "./img/frontpage/hero-02.jpg";
import FrontpagePeople from "./img/frontpage/people-01.jpg";
import FrontpageTestimonies from "./img/frontpage/testimonies-01.jpg";
import Sermons from "./img/sermons/sermons-banner-2023.png";
import Testimonies from "./img/testimonies/testimonies-banner-01.jpg";
import GeneralPaynow from "./img/whats-on/general-paynow.png";
import MissionsPaynow from "./img/whats-on/missions-paynow.png";
import WhatsOnBanner2025 from "./img/whats-on/2025-website-banner.jpg";
import WhoIsJesusBanner from "./img/who-is-jesus/banner-who-is-jesus.jpg";
import WhoIsJesus01 from "./img/who-is-jesus/who-is-jesus-01.jpg";
import WhoIsJesus02 from "./img/who-is-jesus/who-is-jesus-02.jpg";
import WhoIsJesus03 from "./img/who-is-jesus/who-is-jesus-03.jpg";
import WhoIsJesus04 from "./img/who-is-jesus/who-is-jesus-04.jpg";
import WhoIsJesus05 from "./img/who-is-jesus/who-is-jesus-05.jpg";
import WhoIsJesus06 from "./img/who-is-jesus/who-is-jesus-06.jpg";
import WhoIsJesus07 from "./img/who-is-jesus/who-is-jesus-07.jpg";
import WhoIsJesus08 from "./img/who-is-jesus/who-is-jesus-08.jpg";

// ministries imports
import FourOh1 from "./img/ministries/4031/4031-01.jpg";
import FourOh2 from "./img/ministries/4031/4031-02.jpeg";
import FourOh3 from "./img/ministries/4031/4031-03.jpeg";
import FourOh4 from "./img/ministries/4031/4031-04.jpeg";
import MinistryBannerImage from "./img/ministries/about-ministries-banner.jpg";
import Cell1 from "./img/ministries/cells/cell-01.jpg";
import Cell2 from "./img/ministries/cells/cell-02.jpg";
import Evergreen1 from "./img/ministries/evergreen/evergreen-01.jpeg";
import Evergreen2 from "./img/ministries/evergreen/evergreen-02.jpeg";
import IF1 from "./img/ministries/international-fellowship/if-01.jpg";
import IF2 from "./img/ministries/international-fellowship/if-02.jpg";
import JC1 from "./img/ministries/jesus-club/jc-01.jpeg";
import JC2 from "./img/ministries/jesus-club/jc-02.jpeg";
import JC3 from "./img/ministries/jesus-club/jc-03.jpeg";
import KS1 from "./img/ministries/kampung-siglap/ks-01.jpg";
import KS2 from "./img/ministries/kampung-siglap/ks-02.jpg";
import KS3 from "./img/ministries/kampung-siglap/ks-03.jpg";
import KS4 from "./img/ministries/kampung-siglap/ks-04.jpg";
import KS5 from "./img/ministries/kampung-siglap/ks-05.jpg";
import KS6 from "./img/ministries/kampung-siglap/ks-06.jpg";
import KS7 from "./img/ministries/kampung-siglap/ks-07.jpg";
import KS8 from "./img/ministries/kampung-siglap/ks-08.jpg";
import KS9 from "./img/ministries/kampung-siglap/ks-09.jpg";
import KS10 from "./img/ministries/kampung-siglap/ks-10.jpg";
import Kids1 from "./img/ministries/kids/kids-01.jpg";
import Kids2 from "./img/ministries/kids/kids-02.jpg";
import Kids3 from "./img/ministries/kids/kids-03.jpg";
import Kids4 from "./img/ministries/kids/kids-04.jpg";
import Kids5 from "./img/ministries/kids/kids-05.jpg";
import Ladies1 from "./img/ministries/ladies/ladies-01.jpg";
import Limo1 from "./img/ministries/limousine/limo-01.jpeg";
import Limo2 from "./img/ministries/limousine/limo-02.jpeg";
import Youth1 from "./img/ministries/youth/youth-01.jpg";
import Youth2 from "./img/ministries/youth/youth-02.jpg";
import Youth3 from "./img/ministries/youth/youth-03.jpg";
import Youth4 from "./img/ministries/youth/youth-04.jpg";
import Missions01 from "./img/ministries/missions/missions-01.jpg";
import Missions02 from "./img/ministries/missions/missions-02.jpg";

// about people
import AboutPeople from "./img/people/AboutBFEC.jpg";

// operations team imports
import ChingYun from "./img/people/operations/hwang-ching-yun.jpg";
import Jenny from "./img/people/operations/jenny.jpg";
import JohnTeo from "./img/people/operations/john-teo.jpg";
import Julienne from "./img/people/operations/julienne-ng.jpg";
import SeowCheng from "./img/people/operations/lim-seow-cheng.jpg";
import NgooCY from "./img/people/operations/ngoo-chih-yuan.jpg";
import OperationsBanner1 from "./img/people/operations/operations-team-banner-01.jpg";
import OperationsBanner2 from "./img/people/operations/operations-team-banner-02.jpg";
import OperationsBanner3 from "./img/people/operations/operations-team-banner-03.jpg";
import Sharon from "./img/people/operations/sharon-ngoo.jpg";
import Shirley from "./img/people/operations/shirley-soo.jpg";
import Sumawati from "./img/people/operations/sumawati.jpeg";

// pastoral team imports
import Jacqueline from "./img/people/pastoral/jacqueline-woon.jpg";
import JasonSin from "./img/people/pastoral/jason-sin.jpg";
import JimmyTan from "./img/people/pastoral/jimmy-tan.jpg";
import Kelvin from "./img/people/pastoral/kelvin-ng.jpg";
import LeckPing from "./img/people/pastoral/kuah-leck-ping.jpg";
import PastoralBanner1 from "./img/people/pastoral/pastoral-team-banner-01.jpg";
import PastoralBanner2 from "./img/people/pastoral/pastoral-team-banner-02.jpg";
import FangFang from "./img/people/pastoral/tan-fang-fang.jpg";
import GraceWee from "./img/people/pastoral/grace-wee.jpg";

import Council from "./img/people/council_24.jpg";

// bkk imports
import BKKFrontpage from "./img/bkk/bkk-webbanner-2024.jpg";
import BKKFullDayNotice from "./img/bkk/bkk-fulldaynotice-webbanner.jpg";
import BKKPrincipalMsg from "./img/bkk/bkk-principals-message.jpg";
import BKKSafeSpace from "./img/bkk/bkk-safe-space-banner.jpg";
import BKKWongLP from "./img/bkk/bkk-wong-lp.jpg";
import Testimonial1 from "./img/bkk/testimonials/testimonial-01.png";
import Testimonial2 from "./img/bkk/testimonials/testimonial-02.png";
import Testimonial3 from "./img/bkk/testimonials/testimonial-03.png";
import Testimonial4 from "./img/bkk/testimonials/testimonial-04.png";

// missions imports
import MissionsBanner1 from "./img/missions/missions-banner-01.jpg";
import MissionsBanner2 from "./img/missions/missions-banner-02.jpg";

export const BKKBinoculars = require("./img/bkk/6.jpg");
export const BKKPark = require("./img/bkk/20201029_094210.jpg");
export const BKKGraduationGown = require("./img/bkk/IMG-20190227-WA0031.jpg");
export const BKKBlocks = require("./img/bkk/1.jpg");
export const BKKPainting1 = require("./img/bkk/i-54WBSZF.jpeg");
export const BKKPainting2 = require("./img/bkk/i-vhs8dkb.jpeg");
export const BKKConcert = require("./img/bkk/3.Programme-concert.jpg");
export const BKKPlay = require("./img/bkk/2019-10-03 - 08.54.37.LoRes.jpg");
export const BKKChef = require("./img/bkk/i-Z5FcSp8.jpeg");
export const BKKPlayground = require("./img/bkk/i-XZHzdJg.jpeg");

export const GeneralPaynowImage = GeneralPaynow;
export const MissionsPaynowImage = MissionsPaynow;
export const FrontpageHeroes = [FrontpageHero1, FrontpageHero2];
export const FrontpageTestimoniesImage = FrontpageTestimonies;
export const FrontpagePeopleImage = FrontpagePeople;
export const WhatsOnHeroes = [WhatsOnBanner2025];
export const VisitHeroes = [Visit];
export const AboutBFECHeroes = [AboutBFEC];
export const SermonsHero = Sermons;
export const TestimoniesHero = Testimonies;
export const WhoIsJesusBannerHero = WhoIsJesusBanner;
export const WhoIsJesusComics = [
  WhoIsJesus01,
  WhoIsJesus02,
  WhoIsJesus03,
  WhoIsJesus04,
  WhoIsJesus05,
  WhoIsJesus06,
  WhoIsJesus07,
  WhoIsJesus08,
];
export const BKKFullDayNoticeBanner = BKKFullDayNotice;
export const BKKFrontpageHero = BKKFrontpage;
export const BKKSafeSpaceHero = BKKSafeSpace;
export const BKKWongLPIMG = BKKWongLP;
export const BKKPrincipalMsgHero = BKKPrincipalMsg;
export const BKKTestimonials = [
  Testimonial1,
  Testimonial2,
  Testimonial3,
  Testimonial4,
];

export const MinistryBanner = [MinistryBannerImage];

export const MinistryImgs = {
  FourOh: [FourOh1, FourOh2, FourOh3, FourOh4],
  Cell: [Cell1, Cell2],
  Evergreen: [Evergreen1, Evergreen2],
  Kids: [Kids2, Kids3, Kids4, Kids5],
  IF: [IF1, IF2],
  JC: [JC1, JC2, JC3],
  KS: [KS1, KS2, KS3, KS4, KS5, KS6, KS7, KS8, KS9, KS10],
  Ladies: [Ladies1],
  Limousine: [Limo1, Limo2],
  Youth: [Youth1, Youth2, Youth3, Youth4],
  MinistryHero: Kids1,
  Missions: [Missions01, Missions02],
};

export const AboutPeopleBanner = [AboutPeople];

export const OperationsBanners = [
  OperationsBanner1,
  OperationsBanner2,
  OperationsBanner3,
];
export const PastoralBanners = [PastoralBanner1, PastoralBanner2];

export const CouncilBanner = [Council];

export const PeopleImgs = {
  ChingYunImage: ChingYun,
  JennyImage: Jenny,
  JohnTeoImage: JohnTeo,
  JulienneImage: Julienne,
  SeowChengImage: SeowCheng,
  NgooCYImage: NgooCY,
  SharonImage: Sharon,
  ShirleyImage: Shirley,
  JacquelineImage: Jacqueline,
  JimmyTanImage: JimmyTan,
  KelvinImage: Kelvin,
  LeckPingImage: LeckPing,
  FangFangImage: FangFang,
  JasonImage: JasonSin,
  GraceWeeImage: GraceWee,
  SumawatiImage: Sumawati,
};

export const MissionBanners = [MissionsBanner1, MissionsBanner2];

export const AllImages: string[] = [
  ...FrontpageHeroes,
  ...WhatsOnHeroes,
  ...VisitHeroes,
  ...AboutBFECHeroes,
  FrontpageTestimonies,
  FrontpagePeople,
  GeneralPaynow,
  MissionsPaynow,
  Sermons,
  // ...Object.values(Object.values(MinistryImgs)),
];
